import React, { useRef } from "react";
import { SubHeader, DefaultButton, CustomDropdown, CustomInput, BackButton } from "../..";
import * as CONSTANTS from "../../../constants/Global";

function StepOneComponent(props) {
    const { state, onchangeGrandparendName, onchangeRelationship, saveStepOneAndNext, relationshipOptions, fromWebsite } = props;
    const { inputTo, grandpa, grandma, grandmaError, grandpaError } = state;
    const dropDownRef = useRef(null);

    let nextStepDisdabled = true;

    if (inputTo) {
        if (inputTo === CONSTANTS.GRANDPARENTS && (grandpa && grandma)) {
            nextStepDisdabled = false;
        } else if ((inputTo === CONSTANTS.GRANDMA && grandma) || (inputTo === CONSTANTS.GRANDPA && grandpa)) {
            nextStepDisdabled = false;
        }
    }

    let inputsToShow = null;

    if (inputTo === CONSTANTS.GRANDPARENTS) {
        inputsToShow = (
            <>
                <CustomInput
                    id={CONSTANTS.GRANDPA}
                    placeholder="What do you call your grandpa?"
                    onChangeAction={onchangeGrandparendName}
                    value={grandpa}
                    errorMessage={grandpaError}
                    label="What do you call your grandpa?"
                />
                <CustomInput
                    id={CONSTANTS.GRANDMA}
                    placeholder="What do you call your grandma?"
                    onChangeAction={onchangeGrandparendName}
                    value={grandma}
                    errorMessage={grandmaError}
                    label="What do you call your grandma?"
                />
            </>
        );
    } else {
        if (inputTo === CONSTANTS.GRANDMA) {
            inputsToShow = (
                <CustomInput
                    id={CONSTANTS.GRANDMA}
                    placeholder="What do you call your grandma?"
                    onChangeAction={onchangeGrandparendName}
                    value={grandma}
                    label="What do you call your grandma?"
                    errorMessage={grandmaError}
                />
            );
        } else {
            if (inputTo === "") {
                inputsToShow = (
                    <CustomInput
                        id={CONSTANTS.GRANDPA}
                        placeholder="What do you call your old love ?"
                        onChangeAction={onchangeGrandparendName}
                        value={grandpa}
                        label="What do you call your grandpa?"
                        errorMessage={grandpaError}
                    />
                );
            } else {
                inputsToShow = (
                    <CustomInput
                        id={CONSTANTS.GRANDPA}
                        placeholder="What do you call your grandpa?"
                        onChangeAction={onchangeGrandparendName}
                        value={grandpa}
                        label="What do you call your grandpa?"
                        errorMessage={grandpaError}
                    />
                );
            }
        }
    }

    return (
        <section className="padding-page">
            <SubHeader
                mainTitle="Let's Make It The Right Box"
                specialText="Answer some basic questions so we can get the right box."
                hasDivider={true}
            />
            <div className="input-container container-padding">
                <p className="form-header">Tell us about that elderly person you love so much.</p>
                <CustomDropdown
                    reference={dropDownRef}
                    label="Relationship"
                    options={relationshipOptions}
                    placeholder="Select Relationship"
                    onChangeAction={onchangeRelationship}
                    value={inputTo}
                />
                {inputsToShow}

                <div className="margin-top">
                    <DefaultButton title="NEXT STEP" disabled={nextStepDisdabled} buttonAction={saveStepOneAndNext} />
                </div>
                <div>
                <BackButton title="Back" fromWebsite={fromWebsite}/>
            </div>

            </div>
        </section>
    );
}

export default StepOneComponent;
